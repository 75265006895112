import * as React from "react"

// markup
const HeaderComponent = () => {
  return (
    <div className="container">
        <div className="logo float-left">
            <a href="/"><img src="../static/logo_light_blue.png" alt="" className="img-fluid" height="25px" /></a>
        </div>
        <nav className="nav-menu float-right d-none d-lg-block">
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="https://demo.kvotum.com">App Demo</a></li>
                <li><a href="/contact">Contact Us</a></li>
            </ul>
        </nav>
    </div>
  )
}

export default HeaderComponent