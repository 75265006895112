import * as React from "react"

// markup
const FooterComponent = () => {
  return (
    <>
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Regulatory information</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/privacy">Privacy Notice</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/cookies">Cookie Notice</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Information</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/risk">Risk and Investment</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/contact">Contacts</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-info">
          </div>
          <div className="col-lg-3 col-md-6 footer-contact">
            <h4>Kvotum OU</h4>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-info">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">      
            <p className="text-muted pt-2" style={{fontSize: "0.7em"}}>Kvotum OU is a limited liability company registered in the Commercial Register of the Estonia under registration number of 16089383, with legal address at Harju maakond, Kuusalu vald, Pudisoo küla, Männimäe/1, 74626. Kvotum is not regulated under any financial service licence. Kvotum is a marketplace for secondary loan investments that originate from its partnering crowdfunding and P2P lending platforms. When you invest on Kvotum, you buy claim rights for loan receivables and those are not deposits. Investment in loan receivables are subject to risks. We advice to carefully evaluate the risks and diversify investments</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default FooterComponent