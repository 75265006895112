import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const CookiesPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'Cookie Policy'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
    <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>Cookie Policy</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Cookie Policy</li>
                </ol>
                </div>
            </div>
        </section>
        <section className="about" data-aos="fade-up">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 pt-4 pt-lg-0 text-justify">
                    <h3>Cookie Policy of Kvotum</h3>
                    <h5>General acceptance or denial of Cookies</h5>
                    <p>
                    You can agree or disagree to accept any cookies in general by activating the appropriate setting on the cookie consent notification displayed on our website. However, if you select “I decline” you may be unable to access certain parts or several functions of our website, and we cannot guarantee that our website will function properly. Otherwise our system will issue cookies when you direct your browser to our website.
                    </p>
                    <h5>How to manage cookies</h5>
                    <p>
                    You can change your browser settings so that you receive a warning before certain cookies are stored. You can also adjust your settings so that your browser refuses most of our cookies or only certain cookies from third parties. You can also withdraw your consent to cookies by deleting the cookies that have already been stored.
                    </p>
                    <p>
                    Please note that you will have to change your settings for each browser and device you use. Moreover, such methods will not work with respect to certain non-cookie online tracking technologies. The procedures for changing your settings and cookies differ from browser to browser. If necessary, use the help function on your browser or click on one of the links below to go directly to the user manual for your browser.
                    </p>
                    <ul style={{listStyle: 'disc', paddingLeft: '3em'}} >
                        <li><a target="_blank" rel="noreferrer" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">Mozilla Firefox</a></li>
                        <li><a target="_blank" rel="noreferrer" href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647">Google Chrome</a></li>
                        <li><a target="_blank" rel="noreferrer" href="http://support.apple.com/kb/PH5042">Safari</a></li>
                        <li><a target="_blank" rel="noreferrer" href="http://www.opera.com/browser/tutorials/security/privacy/">Opera</a></li>
                    </ul>
                    <p>
                    There are also software products available that can manage cookies for you. You can also use <a href="www.ghostery.com">www.ghostery.com</a> to accept or refuse each cookie used on our website.
                    </p>
                    <h5>Description of Cookies and the way we use them</h5>
                    <p>
                    Our website uses cookies to keep the website operating as smoothly as possible and to provide web services and functionalities for each visitor. Cookies are used for a variety of purposes. For example, they calculate web statistics, provide online advertising and improve a visitor's experience on our website. As your privacy is important to us, we would like to inform you about which cookies are used on our website, as well as why we use them. We operate the following cookies on our website:
                    </p>
                    <ul style={{listStyle: 'disc', paddingLeft: '3em'}}>
                    <li>Browser Cookies. A browser cookie is a small file placed on the hard drive of your computer.</li>
                    <li>Flash Cookies. Certain features of our website may use local stored objects (or Flash cookies) to collect and store information (including personal data) about your preferences and navigation to, from, and on our website.</li>
                    <li>Web Beacons. Pages of our website and our emails may contain small electronic files known as Web Beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                    </ul>
                    <p>We refer to cookies, flash cookies and web beacons as "cookies".</p>
                    <p>
                    We may collect personal data automatically, and we may tie this personal data to personal data about you that we collect from other sources or you provide to us. Our cookies are used in the following way and for the following purposes:
                    </p>
                    <ul style={{listStyle: 'disc', paddingLeft: '3em'}}>
                    <li>Functional cookies: these cookies are essential in order to ensure that our website operates properly. They are used to save preferences, detect misuse of our website and services, and to distribute the load on our servers, which keeps our website available. Certain services you may request cannot be provided without these cookies.</li>
                    <li>
                        Web statistics cookies: these cookies are used to determine which parts of our website interests our visitors. This enables us to make the structure, navigation, and content of our website as user friendly as possible.  We and the parties with whom we work (e.g., service providers, business partners, advertisers and advertising servers) may place, view, and/or use cookies, Web server logs, Web Beacons, or other electronic tools to collect statistical and other usage information about your use of this website and all other websites which relate to, provide, or market Kvotum services. This usage information may include information about the IP address of your computer, browser type, language, operating system, your mobile device, geo-location data, internet service provider, the state or country from which you accessed this website, the Web pages visited, the date and the time of a visit, the websites you visited immediately before and after visiting this website, the number of links you click within this website, the functions you use on this website, the databases you view and the searches you request on this website, the data you save on or download from this website and the number of times you view an advertisement.
                    </li>
                    <li>
                        Social media cookies: our Website may integrate certain third-party plug-ins (such as a Facebook "like" button).  Even if you do not click on these plug-ins, they may collect information about you, such as your IP address and the pages that you view. These plugins are governed by the privacy policy of the third party providing them.
                    </li>
                    <li>
                        <p>Advertising cookies: our Website may also enable third-party tracking mechanisms to collect data over time and across unaffiliated websites for use in interest-based advertising. For example, third parties may use the fact that you visited the website to send you online ads for Kvotum services on third party websites.  In addition, our third-party advertising partners might use information about you to send you targeted advertisements based on your online behavior in general. Information (including personal data) about your visits to, and activity on, this website and other websites, an IP address, the number of times you have viewed an advertisement, and other such usage information is used, alone or in combination with other information and personal data, to display on your device screen advertisements that may be of particular interest to you. We may use Web Beacons, provided by third party advertising companies, to help manage and optimize our online advertising and service performance. Web Beacons enable us to recognize a browser’s cookie when a browser visits this website, and to learn which banner ads bring users to this website. The use and collection of your information and personal data by these third-party service providers, and third party advertisers and their advertising servers is not covered by this Cookie Policy.</p>
                        <ul style={{listStyle: 'circle', paddingLeft: '3em'}}>
                        <li>
                            To opt out of advertising cookies for Your Online Choices if you are based in the EU, please click <a target="_blank" rel="noreferrer" href="http://youronlinechoices.eu/">here</a>.
                        </li>
                        <li>
                            To opt out of Google Analytics for Display Advertising, please click <a target="_blank" rel="noreferrer" href="https://www.google.com/settings/ads">here</a>.
                        </li>
                        <li>
                            You may also enable the “Do Not Track” setting in your web browser(s) or clear cookies from your browser after each browsing session. Please note that even if you exercise this choice for targeted advertising, you will still receive online advertising.
                        </li>
                        </ul>
                    </li>
                    <li>
                        Matching or connecting cookies: We or our third-party service providers also may use collected information to establish connections among related web browsers and devices (such as smartphones, tablets, computers, and TVs) for targeted advertising, analytics, attribution, and reporting purposes. These third parties may match your browsers or devices if you log into the same online service on multiple devices or if your devices share similar attributes that support an inference that they are used by the same person or household. This means that information about your activity on this website or apps on your current browser or device may be combined with information collected from your other browsers or devices.
                    </li>
                    <li>
                        Other cookies: this category includes cookies that do not fit into one of the above cookie categories. One example of this is the bundling of several of the cookies via Google Tag Manager.
                    </li>
                    </ul>
                    <p>
                    To find out more about cookies, including how to see what cookies have been set and how to manage or delete them, visit <a target="_blank" href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
                    </p>
                </div>
                </div>
            </div>
        </section>
    </main>
    <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
    </footer>
    </>
  )
}

export default CookiesPage